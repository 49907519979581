import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import Success from "@components/SuccessComponent/SuccessComponent";

const Goal = ({ order }) => {
  const {
    state: { plan, tabs },
  } = useContext(PlanContext);
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const goalContent = tabs[order];

  const successData = [
    {
      order: 3,
      componentType: 5,
      headerImage:
        "https://images.unsplash.com/photo-1631347826177-de288776ed3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
      header: `"Tesla Model X"`,
      subHeader: `"VEHICLE INFORMATION"`,
      data: [
        {
          component: "header",
          title: `"CHARGE STATE"`,
        },
        {
          component: "progress",
          title: `"Battery Level:" + " " + "30/100"`,
          value: `30`,
          max: `100`,
        },
        {
          component: "gradient-stat",
          key: `"CHARGE TIME LEFT"`,
          value: `350`,
          unit: `"min"`,
        },
        {
          component: "checklist",
          data: [
            {
              key: "Plugged In",
              value: true,
            },
            {
              key: "Charging",
              value: false,
            },
            {
              key: "Fully Charged",
              value: false,
            },
          ],
        },
      ],
    },
    {
      componentType: 1, // Entity Action
      headerIcon: "battery-charging",
      header: `"Charging Policy Change"`,
      subHeader: `"Vehicle ID:" + " " + "349ssjkbc8"`,
      statusLabel: `"ACTIVATED"`,
      actionImage: `"https://www.carlogos.org/car-logos/ford-logo-2017.png"`,
      actionSubHeader: `"Charging Deadline"`,
      actionHeader: `"8:00" + " " + "UTC"`,
      successLabel: `"Smart Changing Enabled"`,
      data: {},
    },
    {
      componentType: 3, // Data Visualization
      headerIcon: "bar-chart",
      header: `"Charging Management"`,
      subHeader: `"Ford"`,
      successLabel: `"Charging Data Retrieved"`,
      dataLabels: ["kW", "Price", "NonSmart Price"],
      data: [
        {
          name: "6/04",
          kW: 4000,
          Price: 2400,
          "NonSmart Price": 2400,
        },
        {
          name: "6/05",
          kW: 3000,
          Price: 1398,
          "NonSmart Price": 2210,
        },
        {
          name: "6/06",
          kW: 2000,
          Price: 9800,
          "NonSmart Price": 2290,
        },
        {
          name: "6/07",
          kW: 2780,
          Price: 3908,
          "NonSmart Price": 2000,
        },
        {
          name: "6/08",
          kW: 1890,
          Price: 4800,
          "NonSmart Price": 2181,
        },
        {
          name: "6/09",
          kW: 2390,
          Price: 3800,
          "NonSmart Price": 2500,
        },
        {
          name: "6/10",
          kW: 3490,
          Price: 4300,
          "NonSmart Price": 2100,
        },
      ],
    },
  ];

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: plan.company.headingColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: plan.company.darkColor,
              marginBottom: -10,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h1
          >
            {goalContent.title}
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: plan.company.darkColor }} font="16px">
            {goalContent.description}
          </Text>
          <View style={{ marginVertical: 10 }} />
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              style={{ fontWeight: "bold" }}
              type="secondary"
              onClick={() =>
                // lastComp.current.scrollIntoView({
                //   behavior: "smooth",
                // })
                {
                  setAnimating(true);
                }
              }
            >
              Start Animation
            </Button>
          </View>
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      <View style={styles.scrollContainer}>
        <ScrollView
          ref={scrollRef}
          horizontal
          contentContainerStyle={styles.animationContainer}
        >
          <motion.div
            style={{
              alignSelf: "center",
              height: 500,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={[
                styles.databaseView,
                { padding: 30, justifyContent: "center", alignSelf: "center" },
              ]}
            >
              <Image
                source={require("../../../../../../assets/images/lock-dynamic-premium.png")}
                style={{
                  height: 200,
                  width: 200,
                  alignSelf: "center",
                }}
              />
              <View style={{ marginVertical: 10 }} />
              <TouchableOpacity
                style={{
                  backgroundColor: plan.company.darkColor,
                  width: "100%",
                  paddingVertical: 8,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  flexDirection: "row",
                  paddingHorizontal: 15,
                }}
              >
                <Ionicons
                  name="ios-checkmark-circle"
                  size={24}
                  color={plan.company.lightColor}
                />
                <Text style={{ color: plan.company.lightColor }} small b>
                  Authentication
                </Text>
                <View />
              </TouchableOpacity>
            </View>
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center", height: 500 }}
            transition={{ delay: 0.2 }}
          >
            <Success data={successData[0]} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
            transition={{ delay: 0.8 }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 0.8 }}
            style={{ alignSelf: "center", width: 400, height: 500 }}
          >
            <Success data={successData[1]} height={500} />
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              if (animating)
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +1000,
                  behavior: "smooth",
                });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.2 }}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 1.2 }}
            style={{ alignSelf: "center", width: 400, height: 500 }}
          >
            <Success data={successData[2]} height={500} />
          </motion.div>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;
