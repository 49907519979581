import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toast } from "react-toastify";
import generateGradient from "../functions/generateGradient";

const onErr = async (err, route) => {
  console.log(`${route} error`);
  console.log(err);
  const userIdentification = await AsyncStorage.getItem("userId");
  Sentry.Browser.configureScope(function (scope) {
    scope.setExtra("function_name", route);
    scope.setExtra("id", userIdentification);
    Sentry.Browser.captureException(err);
  });
  toast.error("Having trouble connecting to the server... Try again in a few.");
};

const calcTabs = (content) => {
  const tabs = [];

  if (content.letter != null && content.letter != "") {
    tabs.push({
      order: 0,
      type: 0,
      title: "Overview",
    });
  }

  for (let i = 0; i < content.length; i++) {
    tabs.push({
      order: tabs.length,
      type: 1, // GOAL
      title: content[i].goalTitle,
      content: content[i].goal,
    });

    const goalOrder = tabs.length - 1;
    let stepOrder = 0;

    const stepArr = content[i].steps;
    for (let ii = 0; ii < stepArr.length; ii++) {
      tabs.push({
        order: tabs.length,
        type: 2, // STEP
        goalOrder: goalOrder,
        stepNumber: stepOrder,
        title: stepArr[ii].stepTitle,
        description: stepArr[ii].stepDescription,
        content: stepArr[ii].step,
      });
      stepOrder++;
    }
  }

  tabs.push({
    order: tabs.length,
    type: 3, // NEXT STEPS
    title: "Next Steps",
    content: content.nextSteps,
  });

  return tabs;
};

const genGradients = (number) => {
  let gradients = [];
  for (let i = 0; i < number; i++) {
    gradients.push(generateGradient());
  }
  return gradients;
};

const planReducer = (state, action) => {
  switch (action.type) {
    case "fetch_plan":
      return {
        ...state,
        plan: action.payload,
        tabs: calcTabs(action.payload.content),
        gradients: genGradients(calcTabs(action.payload.content).length),
      };
    case "select_tab":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "reset":
      return {
        plan: null,
        selectedTab: 0,
      };
    default:
      return state;
  }
};

const fetchPlan = (dispatch) => async (planId) => {
  try {
    const response = await serverApi.post("/plan", { planId: planId });
    dispatch({ type: "fetch_plan", payload: response.data });
  } catch (err) {
    onErr(err, "fetchPlan");
  }
};

const selectTab = (dispatch) => async (tab) => {
  dispatch({ type: "select_tab", payload: tab });
};

const resetPlanContext = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  planReducer,
  {
    fetchPlan,
    selectTab,
    resetPlanContext,
  },
  {
    plan: {
      company: {
        _id: "example-method-id",
        name: "Enode",
        logo: "https://i.ibb.co/Z6szBjJ/Enode-Logo.png",
        email: "HENRIK@ENODE.IO",
        lightColor: "#00cf61",
        darkColor: "#002203",
        headingColor: "#00780C",
        fontImport: "Space Grotesk:300,400,800",
        bodyFont: "Space Grotesk",
        headingFont: "Space Grotesk:300",
      },
      prospectCompany: "Acme, Inc.",
      prospectName: "John",
      apiKey: "sk_SRewVmZa38kx9BWLCjLk3cVK",
      letter:
        "I've gone ahead and mapped out an integration plan according to your needs, and generated a Sandbox API Key for you.\nThis will closely resemble the steps your engineering team will take to integrate Enode.\nIf you have any questions don’t hesitate to reach out directly.\nBest, The Enode Team",
      // prospectLogo:
      //   "https://deferit.com/images/deferit-public-logo.0a942594d2efc871d81f7b6491b8f9df4946838839feb1d394ba8c05cd61d75d.svg",
      // prospectIcon:
      //   "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/om6pejoyjy22x28dqika",

      content: [
        {
          goalTitle: "Enable Smart Charging",
        },
      ],
    },
    tabs: [
      {
        order: 0,
        type: 0,
        title: "Overview",
      },
      {
        order: 1,
        type: 1,
        title: "Enable Smart Charging",
        description: `The Enode API makes it easy to add smart charging functionality to your app. Let your users experience a frictionless smart charging sessions with insights into their custom smart charging plan, their estimated saving and the climate impact they are contributing to.`,
      },
      {
        order: 2,
        type: 2,
        goalOrder: 1,
        stepNumber: 1,
        title: "Connect a Vehicle",
        dbTitle: "connect",
        description: `Try out the Enode connect process! We have the frontend UI widgets to get your connect flow up and running in minutes.`,
        content: {
          type: 2,
          objectName: "Vehicle",
          itemName: `localDatabase.connect.name + " " + "ID.3"`,
          logoUrl:
            "https://bookface-images.s3.amazonaws.com/logos/5b13318b6e93734842365908bdab78235a8161a4.png",
          data: [
            {
              id: "id_tesla",
              parent_name: "Tesla",
              name: "Tesla",
              logo: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png",
            },
            {
              id: "id_volkswagen",
              parent_name: "Volkswagen",
              name: "Volkswagen",
              logo: "https://www.senkyr.cz/data/katalog/kategorie/834-volkswagen_logo.png",
            },
            {
              id: "id_audi",
              parent_name: "Audi",
              name: "Audi",
              logo: "https://logos-world.net/wp-content/uploads/2021/03/Audi-Logo.png",
            },
            {
              id: "id_bmw",
              parent_name: "BMW",
              name: "BMW",
              logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/BMW_logo_%28gray%29.svg/1200px-BMW_logo_%28gray%29.svg.png",
            },
            {
              id: "id_jaguar",
              parent_name: "Jaguar",
              name: "Jaguar",
              logo: "https://www.carlogos.org/logo/Jaguar-symbol-green-1920x1080.png",
            },
            {
              id: "id_hyundai",
              parent_name: "Hyundai",
              name: "Hyundai",
              logo: "https://www.hmmausa.com/wp-content/uploads/2020/08/Hyundai-Logo-Stacked_288.png",
            },
            {
              id: "id_toyota",
              parent_name: "Toyota",
              name: "Toyota",
              logo: "https://1000logos.net/wp-content/uploads/2018/02/toyota_logo_2020.jpg",
            },
            {
              id: "id_porsche",
              parent_name: "Porsche",
              name: "Porsche",
              logo: "https://upload.wikimedia.org/wikipedia/en/thumb/8/8c/Porsche_logo.svg/1200px-Porsche_logo.svg.png",
            },
            {
              id: "id_ford",
              parent_name: "Ford",
              name: "Ford",
              logo: "https://www.carlogos.org/car-logos/ford-logo-2017.png",
            },
            {
              id: "id_nissan",
              parent_name: "Nissan",
              name: "Nissan",
              logo: "https://www.carlogos.org/car-logos/nissan-logo-2020-black.png",
            },
          ],
        },
      },
      {
        order: 3,
        type: 2,
        goalOrder: 1,
        stepNumber: 2,
        title: "Vehicle Information",
        dbTitle: "vehicle",
        description: `The Enode API is designed to make smart charging applications easy to develop. We provide an abstraction layer that reduces the complexity when extracting vehicle data and sending commands to vehicles from a variety of manufacturers.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl --request GET
          --url https://enode-api.production.enode.io/vehicles/f7a4346d
          --header 'Accept: application/json'`,
          requestFields: [
            {
              key: "vehicleId",
              value: "f7a4346d",
              type: 1,
              desc: "Vehicle ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
          ],
          botDetails: [
            {
              title: "Establishing Vehicle Connection",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
                {
                  key: "Vehicle ID",
                  value: "3829382",
                },
              ],
            },
            {
              title: "Pinging Remote Server",
              sub: [
                {
                  key: "Status",
                  value: "Verified",
                },
              ],
            },
            {
              title: "Retrieving Vehicle Data",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
          ],
          payload: {
            id: "string",
            isReachable: true,
            lastSeen: "2019-08-24",
            chargingLocationId: "8d90101b-3f2f-462a-bbb4-1ed320d33bbe",
            information: {
              id: "8d90101b-3f2f-462a-bbb4-1ed320d33bbe",
              brand: "Tesla",
              model: "Model S P85",
              year: 2020,
            },
            chargeState: {
              batteryLevel: 38,
              range: 127.5,
              isPluggedIn: true,
              isCharging: false,
              isFullyCharged: false,
              isChargingReasons: ["SMART_CHARGING_DELAY"],
              batteryCapacity: 73.21,
              chargeLimit: 80,
              chargeRate: 40.1,
              chargeTimeRemaining: 319,
              lastUpdated: "2020-01-07T16:21:76Z",
            },
            smartChargingPolicy: {
              isEnabled: true,
              deadline: "08:00",
            },
            location: {
              longitude: 10.757933,
              latitude: 59.911491,
              lastUpdated: "2020-04-07T17:04:26Z",
            },
            odometer: {
              distance: 24650,
              lastUpdated: "2020-01-07T16:21:76Z",
            },
            capabilities: {
              chargeState: {
                isCapable: true,
                hasIntervention: true,
              },
              location: {
                isCapable: true,
                hasIntervention: true,
              },
              information: {
                isCapable: true,
                hasIntervention: true,
              },
              odometer: {
                isCapable: true,
                hasIntervention: true,
              },
              startCharging: {
                isCapable: true,
                hasIntervention: true,
              },
              stopCharging: {
                isCapable: true,
                hasIntervention: true,
              },
              smartCharging: {
                isCapable: true,
                hasIntervention: true,
              },
            },
          },
          success: {
            order: 3,
            componentType: 5, // PhoneDisplay
            headerImage:
              "https://images.unsplash.com/photo-1631347826177-de288776ed3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80",
            header: `localDatabase.vehicle.information.brand + " " + localDatabase.vehicle.information.model`,
            subHeader: `"VEHICLE INFORMATION"`,
            data: [
              {
                component: "header",
                title: `"CHARGE STATE"`,
              },
              {
                component: "progress",
                title: `"Battery Level:" + " " + localDatabase.vehicle.chargeState.batteryLevel + "/" + localDatabase.vehicle.chargeState.batteryCapacity`,
                value: `localDatabase.vehicle.chargeState.batteryLevel`,
                max: `localDatabase.vehicle.chargeState.batteryCapacity`,
              },
              {
                component: "gradient-stat",
                key: `"CHARGE TIME LEFT"`,
                value: `localDatabase.vehicle.chargeState.chargeTimeRemaining`,
                unit: `"min"`,
              },
              {
                component: "checklist",
                data: [
                  {
                    key: "Plugged In",
                    value: true,
                  },
                  {
                    key: "Charging",
                    value: false,
                  },
                  {
                    key: "Fully Charged",
                    value: false,
                  },
                ],
              },
            ],
          },
        },
      },
      {
        order: 4,
        type: 2,
        goalOrder: 1,
        stepNumber: 3,
        title: "Initiate Smart Charging",
        dbTitle: "charge",
        description: `The default behavior of an EV, when plugged in, is to start charging immediately. Smart Charging replaces this default behavior and instead attempts to charge the EV whenever the power consumption is the least expensive.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl https://enode-api.production.enode.io/vehicles/f7a4346d/smart-charging-policy
          -X PUT
          -H "Content-Type: application/json"
          -d '{
            "isEnabled": "true",
            "deadline": "08:00"
          }'`,
          requestFields: [
            {
              key: "vehicleId",
              value: "f7a4346d",
              type: 1,
              desc: "Vehicle ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
            {
              key: "isEnabled",
              value: "true",
              type: 0,
              desc: "Enable Smart Charging",
            },
            {
              key: "deadline",
              value: "08:00",
              type: 0,
              desc: "Fully Charged Deadline (UTC 24h)",
            },
          ],
          botDetails: [
            {
              title: "Accessing Vehicle Settings",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Manipulating Charge Schedule",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Calculating Efficiency",
            },
            {
              title: "Implementing Schedule",
            },
          ],
          payload: {
            isEnabled: true,
            deadline: "08:00",
          },
          success: {
            componentType: 1, // Entity Action
            headerIcon: "battery-charging",
            header: `"Charging Policy Change"`,
            subHeader: `"Vehicle ID:" + " " + localDatabase.connect.id`,
            statusLabel: `"ACTIVATED"`,
            actionImage: `localDatabase.connect.logo`,
            actionSubHeader: `"Charging Deadline"`,
            actionHeader: `localDatabase.charge.deadline + " " + "UTC"`,
            successLabel: `"Smart Changing Enabled"`,
            data: {},
          },
        },
      },
      {
        order: 5,
        type: 2,
        goalOrder: 1,
        stepNumber: 4,
        title: "Charging Analysis",
        dbTitle: "analysis",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer a faucibus neque, vitae condimentum magna. Vestibulum metus elit, facilisis in suscipit eget, elementum at enim. Aliquam ligula lectus, rutrum consectetur varius a, auctor vel metus. Maecenas luctus, leo non vulputate finibus, arcu sem accumsan erat, eget pulvinar lacus tortor in purus.`,
        content: {
          type: 0,
          firstParam: "type",
          lastParam: "dob",
          codeSnippet: `curl https://enode-api.production.enode.io/statistics/charging
          -X GET
          -H "Content-Type: application/json"
          -d '{
            "startDate": "2022-06-04",
            "endDate": "2022-06-10",
            "type": "vehicle",
            "id": "f7a4346d"
          }'`,
          requestFields: [
            {
              key: "id",
              value: "f7a4346d",
              type: 1,
              desc: "Vehicle ID",
              preloadKey: "connect.id",
              preloadDesc: "connect.parent_name",
            },
            {
              key: "startDate",
              value: "2022-06-04",
              type: 0,
              desc: "Earliest Date to Fetch",
            },
            {
              key: "endDate",
              value: "2022-06-10",
              type: 0,
              desc: "Latest Date to Fetch",
            },
            {
              key: "type",
              value: "vehicle",
              type: 0,
              desc: "Hardware Type",
            },
          ],
          botDetails: [
            {
              title: "Accessing Vehicle",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Running Query by Dates",
              sub: [
                {
                  key: "Status",
                  value: "Completed",
                },
              ],
            },
            {
              title: "Analyzing Data",
            },
            {
              title: "Parsing and Filtering",
            },
          ],
          payload: {
            data: [
              {
                kw: {
                  min: 0,
                  max: 78,
                  mean: 61,
                },
                kwhSum: 120,
                price: {
                  min: 13.8,
                  max: 14.4,
                  mean: 14.1,
                },
                nonSmartPrice: {
                  min: 12.9,
                  max: 16.7,
                  mean: 14.8,
                },
                costSum: 3.14,
                nonSmartCostSum: 4.21,
                estimatedSavings: 1.07,
                date: "2021-01-19T09:37:36.845Z",
              },
            ],
          },
          success: {
            componentType: 3, // Data Visualization
            headerIcon: "bar-chart",
            header: `"Charging Management"`,
            subHeader: `localDatabase.connect.parent_name`,
            successLabel: `"Charging Data Retrieved"`,
            dataLabels: ["kW", "Price", "NonSmart Price"],
            data: [
              {
                name: "6/04",
                kW: 4000,
                Price: 2400,
                "NonSmart Price": 2400,
              },
              {
                name: "6/05",
                kW: 3000,
                Price: 1398,
                "NonSmart Price": 2210,
              },
              {
                name: "6/06",
                kW: 2000,
                Price: 9805,
                "NonSmart Price": 2290,
              },
              {
                name: "6/07",
                kW: 2780,
                Price: 3908,
                "NonSmart Price": 2000,
              },
              {
                name: "6/08",
                kW: 1890,
                Price: 4800,
                "NonSmart Price": 2181,
              },
              {
                name: "6/09",
                kW: 2390,
                Price: 3800,
                "NonSmart Price": 2500,
              },
              {
                name: "6/10",
                kW: 3490,
                Price: 4300,
                "NonSmart Price": 2100,
              },
            ],
          },
        },
      },
      {
        order: 7,
        type: 4,
        title: "All Done!",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer a faucibus neque, vitae condimentum magna. Vestibulum metus elit, facilisis in suscipit eget, elementum at enim. Aliquam ligula lectus, rutrum consectetur varius a, auctor vel metus. Maecenas luctus, leo non vulputate finibus, arcu sem accumsan erat, eget pulvinar lacus tortor in purus.`,
      },
    ], // FIXME: remove and set to null for prod
    selectedTab: {
      index: 0,
      type: "auto",
    },
    gradients: genGradients(7),
  }
);
