import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "./devices.min.css";
import RemoteImage from "../../RemoteImage";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} resizeMode="contain" />;
});

const Connect = ({ order, stepContent }) => {
  const {
    selectTab,
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [stage, setStage] = useState(0);

  const onSelect = (merchant) => {
    modDatabase({ key: "connect", value: merchant });
  };

  const advanceStage = () => {
    setStage(stage + 1);
  };

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  const step0 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 50,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <RemoteImage desiredWidth={150} uri={stepContent.content.logoUrl} />
        <View style={{ marginVertical: 10 }} />
        <Card>
          <View>
            <Image
              source={require("../../../../assets/images/lock-dynamic-premium.png")}
              style={{
                height: 125,
                width: 125,
                alignSelf: "center",
              }}
            />
            <Text style={{ textAlign: "center" }}>
              Acme, Inc. uses {plan.company.name} to connect your account
            </Text>
          </View>
        </Card>
        <View style={{ marginVertical: 10 }} />
        <Text style={{ color: plan.company.darkColor }} small b>
          {plan.company.name} will be able to read your data and initiate
          actions
        </Text>
        <View style={{ marginVertical: 5 }} />
        <Text style={{ color: "grey" }} small font="11px">
          {plan.company.name} won't have access to your credentials or be able
          to perform any action without your consent.
        </Text>
      </View>
      <Button
        width={"100%"}
        onClick={() => advanceStage()}
        type="secondary-light"
        style={{
          padding: 0,
          backgroundColor: plan.company.darkColor,
          borderColor: plan.company.darkColor,
        }}
      >
        <Text style={{ color: plan.company.lightColor }} b>
          Continue
        </Text>
      </Button>
    </View>
  );

  const step1 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <TouchableOpacity onPress={() => setStage(stage - 1)}>
            <Text style={{ textAlign: "center" }} small font="11px">
              Back
            </Text>
          </TouchableOpacity>
          <Text style={{ textAlign: "center" }} b>
            Select a {stepContent.content.objectName}
          </Text>
          <View />
        </View>
        <View style={{ marginVertical: 10 }} />
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 400,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
            }}
          >
            {stepContent.content.data.map((elem) => {
              const isSelected =
                localDatabase.connect != null &&
                `${localDatabase.connect.id}` == `${elem.id}`;

              return (
                <TouchableOpacity
                  key={`${elem.id}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginVertical: 10,
                    marginHorizontal: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    borderColor: "lightgrey",
                    borderWidth: 0.5,
                  }}
                >
                  <motion.div whileHover={{ scale: 1.1 }} layout>
                    <MemoImage
                      source={{ uri: elem.logo }}
                      style={{
                        width: 100,
                        height: 50,
                        borderRadius: 5,
                        borderColor: isSelected
                          ? plan.company.darkColor
                          : "#F8F8F8",
                        borderWidth: isSelected ? 4 : 4,
                      }}
                    />
                  </motion.div>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      </View>
      {!localDatabase.connect || localDatabase.connect == null ? (
        <Button width={"100%"} style={{ fontWeight: "bold" }} disabled>
          No {stepContent.content.objectName} Selected
        </Button>
      ) : (
        <Button
          width={"100%"}
          style={{
            padding: 0,
            backgroundColor: plan.company.darkColor,
            borderColor: plan.company.darkColor,
          }}
          type="secondary-light"
          onClick={() => {
            advanceStage();
          }}
        >
          <Text style={{ color: plan.company.lightColor }} b>
            {localDatabase.connect.parent_name} Selected
          </Text>
        </Button>
      )}
    </View>
  );

  const step2 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center" }}>
        <RemoteImage desiredWidth={75} uri={localDatabase.connect.logo} />
        <View style={{ marginVertical: 20 }} />
        <Input width={"100%"} placeholder="Ej. 123456" value="HeyYC">
          Client No.
        </Input>
        <View style={{ marginVertical: 20 }} />
        <Input.Password width={"100%"} initialValue="123456abc">
          Password
        </Input.Password>
        <View style={{ marginVertical: 30 }} />
        <Text style={{ color: "grey" }} small font="11px">
          +1M users have securely connected their accounts using{" "}
          {plan.company.name}.
        </Text>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          const resolveAfter3Sec = new Promise((resolve) =>
            setTimeout(resolve, 500)
          );
          toast.promise(resolveAfter3Sec, {
            pending: "Request pending",
            success: "Request resolved 👌",
            error: "Request rejected 🤯",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          advanceStage();
        }}
        style={{
          padding: 0,
          backgroundColor: plan.company.darkColor,
          borderColor: plan.company.darkColor,
        }}
        type="secondary-light"
      >
        <Text style={{ color: plan.company.lightColor }} b>
          Connect Account
        </Text>
      </Button>
    </View>
  );

  const step3 = () => (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingVertical: 75,
        paddingHorizontal: 20,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center", width: "100%" }}>
        <RemoteImage desiredWidth={150} uri={stepContent.content.logoUrl} />
        <View style={{ marginVertical: 30 }} />
        {stepContent.content.itemName != null ? (
          <View style={{ marginBottom: 10, width: "100%" }}>
            <Text style={{ alignSelf: "flex-start" }} h4>
              {stepContent.content.objectName} Identified
            </Text>
          </View>
        ) : null}
        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            width: "100%",
            padding: 15,
          }}
        >
          <View style={{ width: "100%" }}>
            {stepContent.content.itemName == null ? (
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={require("../../../../assets/images/sheild-dynamic-premium.png")}
                  style={{
                    height: 150,
                    width: 150,
                    alignSelf: "center",
                  }}
                />
                <View style={{ marginVertical: 10 }} />
                <Text style={{ textAlign: "center", width: "70%" }} b>
                  {stepContent.content.objectName} Connected Successfully
                </Text>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    source={{ uri: localDatabase.connect.logo }}
                    style={{
                      height: 25,
                      width: 25,
                      borderRadius: 25,
                      marginRight: 10,
                      borderWidth: 1,
                      borderColor: "lightgrey",
                    }}
                    resizeMode="contain"
                  />

                  <Text small b>
                    {eval(stepContent.content.itemName)}
                  </Text>
                </View>
                <Ionicons
                  name="ios-checkmark-circle"
                  size={24}
                  color={plan.company.lightColor}
                />
              </View>
            )}
          </View>
        </View>
      </View>
      <Button
        width={"100%"}
        onClick={() => {
          selectTab({ index: order + 1, type: "manual" });
        }}
        style={{
          padding: 0,
          backgroundColor: plan.company.darkColor,
          borderColor: plan.company.darkColor,
        }}
        type="secondary-light"
      >
        <Text style={{ color: plan.company.lightColor }} b>
          Finish
        </Text>
      </Button>
    </View>
  );

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <View style={{ height: "100%", width: "100%" }}>
            {stage == 0 ? step0() : null}
            {stage == 1 ? step1() : null}
            {stage == 2 ? step2() : null}
            {stage == 3 ? step3() : null}
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Connect;
