import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { View, Text, useWindowDimensions } from "react-native";
import { navigationRef } from "./RootNavigation";
import * as Linking from "expo-linking";

import { Context as PlanContext } from "@context/PlanContext";

// import { socket } from "./api/Socket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { enableScreens } from "react-native-screens";
import HomeScreen from "./screens/Home/HomeScreen";
//import { Mixpanel } from "./api/mixPanel";
import { toast } from "react-toastify";
//import { v4 as uuidv4 } from "uuid";
//import moment from "moment";
import LoadingView from "./components/LoadingView";
import ShrinkView from "./components/ShrinkView";

import WebFont from "webfontloader";
import { CssBaseline, GeistProvider, Loading } from "@geist-ui/react";
import { Themes, GeistUIThemesFont, GeistUIThemes } from "@geist-ui/core";

const queryString = require("query-string");

enableScreens();

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const prefix = Linking.createURL("/");

export default function AppNavigator() {
  const {
    state: { plan },
  } = React.useContext(PlanContext);
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const windowDims = useWindowDimensions();

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        Home: ":id",
      },
    },
  };

  /****
   * HANDLE DEEP LINKS
   */
  const findCompanyName = (path) => {
    let companyName;
    const firstSlash = path.indexOf("/");
    const secondSlash = path.indexOf("/", firstSlash + 1);
    if (secondSlash != -1) {
      companyName = path.substring(firstSlash + 1, secondSlash);
    } else {
      companyName = path.substring(firstSlash + 1);
    }
    return companyName;
  };

  const loadHome = async () => {
    console.log("----RUNNING LOADHOME");

    const companyName = findCompanyName(document.location.pathname);
    // await fetchCompanyInfo(companyName);
  };

  React.useEffect(() => {
    const loadUp = async () => {
      setIsLoading(false);
      if (!isLoading) {
        setIsDataLoaded(false);
        //console.log(findCompanyName(document.location.pathname));
        //await fetchCompanyInfo(findCompanyName(document.location.pathname));
        setIsDataLoaded(true);
      }
    };
    loadUp();
  }, [isLoading]);

  /********
   * HANDLE ANALYTICS
   *******/

  //   React.useEffect(() => {
  //     const loadAnalytics = async () => {
  //       const companyName = findCompanyName(document.location.pathname);
  //       let savedId = await AsyncStorage.getItem("userId");
  //       if (!savedId) {
  //         // No savedId present, create new one
  //         const newId = `anon-${uuidv4()}`;
  //         await AsyncStorage.setItem("userId", newId);
  //         savedId = newId;
  //       }
  //       Mixpanel.identify(savedId);
  //       Mixpanel.track("Opened App", { companyName: companyName });
  //       const peopleSetObject = {
  //         $name: savedId,
  //         USER_ID: savedId,
  //         "Last Opened": moment().format(),
  //       };
  //       Mixpanel.people.set(peopleSetObject);
  //       Mixpanel.people.union("integrations", `${companyName}`);
  //     };
  //     if (isDataLoaded) loadAnalytics();
  //   }, [isDataLoaded]);

  //   if (!companyInfo && isCompanyInfoLoaded) {
  //     return <LostView />;
  //   }

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: [plan.company.fontImport],
      },
      active: () => {
        console.log("Fonts loaded :)");
        setFontsLoaded(true);
      },
    });
  }, []);

  const myTheme = Themes.createFromLight({
    type: "customTheme",
    font: {
      sans: plan.company.bodyFont,
      mono: plan.company.bodyFont,
      prism: plan.company.bodyFont,
    },
  });

  if (windowDims.width < 1100) {
    return <ShrinkView />;
  }

  if (
    !fontsLoaded ||
    isLoading ||
    !isDataLoaded
    // !companyInfo ||
  ) {
    return <LoadingView />;
  } else {
    return (
      <GeistProvider themes={[myTheme]} themeType="customTheme">
        <CssBaseline />
        <NavigationContainer ref={navigationRef} linking={linking}>
          <Stack.Navigator headerShown="none">
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={({ route, navigation }) => ({
                headerShown: false,
                cardStyle: { backgroundColor: "white" },
                title: route.params.id
                  ? `Enode | ${route.params.id[0].toUpperCase()}${route.params.id
                      .slice(1)
                      .toLowerCase()}`
                  : "Coast",
              })}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </GeistProvider>
    );
  }
}
